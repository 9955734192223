html, body {
  width: 100%;
  height: 100%;
  font-family: 'Raleway', sans-serif; }

.container1280 {
  width: 1280px;
  margin: 0 auto; }
  @media only screen and (max-width: 1280px) {
    .container1280 {
      width: 960px; } }
  @media only screen and (max-width: 960px) {
    .container1280 {
      width: 640px; } }
  @media only screen and (max-width: 640px) {
    .container1280 {
      width: 100%; } }

.category {
  text-align: center;
  padding-top: 130px; }
  @media only screen and (max-width: 640px) {
    .category {
      padding-top: 65px; } }
  .category h2 {
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 60px;
    font-weight: 500;
    margin: 0; }
    @media only screen and (max-width: 640px) {
      .category h2 {
        padding: 0 20px;
        font-size: 24px; } }

.project {
  padding-bottom: 130px; }
  .project h3 {
    text-transform: uppercase;
    margin-top: 0;
    padding-top: 70px;
    margin-bottom: 15px;
    font-weight: 200;
    font-size: 36px;
    letter-spacing: 1px; }
    @media only screen and (max-width: 640px) {
      .project h3 {
        padding: 0 20px;
        padding-top: 30px;
        font-size: 18px; } }
  .project h4 {
    font-family: Georgia, serif;
    font-style: italic;
    padding-bottom: 50px;
    font-size: 20px; }
    @media only screen and (max-width: 640px) {
      .project h4 {
        padding: 0 20px;
        padding-bottom: 25px;
        font-size: 14px; } }
  .project .project-image {
    width: 100%; }
    @media only screen and (max-width: 640px) {
      .project .project-image {
        width: 90%; } }

header {
  width: 100%;
  height: 100%;
  background-color: white; }
  header .header-content {
    position: relative;
    height: 380px;
    top: calc(50% - 190px); }
    @media only screen and (max-width: 640px) {
      header .header-content {
        height: 330px;
        top: calc(50% - 140px); } }
    header .header-content .logo {
      width: 260px; }
      @media only screen and (max-width: 640px) {
        header .header-content .logo {
          width: 170px; } }
    header .header-content h1 {
      font-family: Georgia, serif;
      font-style: italic;
      color: #777777;
      font-size: 32px;
      margin-top: 100px;
      margin-bottom: 0; }
      @media only screen and (max-width: 640px) {
        header .header-content h1 {
          margin-top: 50px;
          font-size: 24px;
          padding: 0 20px; } }
  header .arrow {
    position: absolute;
    bottom: 20px;
    text-align: center;
    width: 100%;
    -webkit-animation-name: upAndDown;
            animation-name: upAndDown;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-duration: 2s;
            animation-duration: 2s; }
    header .arrow img {
      cursor: pointer;
      width: 25px; }

@-webkit-keyframes upAndDown {
  0% {
    bottom: 20px; }
  50% {
    bottom: 10px; }
  100% {
    bottom: 20px; } }

@keyframes upAndDown {
  0% {
    bottom: 20px; }
  50% {
    bottom: 10px; }
  100% {
    bottom: 20px; } }

#footer {
  height: 240px;
  width: 100%;
  box-shadow: 0 0 10px #777777; }
  @media only screen and (max-width: 640px) {
    #footer {
      height: 180px; } }
  #footer ul {
    list-style-type: none;
    margin: 0;
    padding: 80px 0 0; }
    #footer ul li {
      cursor: pointer;
      text-transform: uppercase;
      padding: 0 10px;
      display: inline-block;
      letter-spacing: 1px; }
      #footer ul li:hover {
        text-decoration: underline; }
      #footer ul li a {
        text-decoration: none;
        color: black; }
        #footer ul li a:hover {
          color: black; }

.mobile-nav-toggle {
  width: 40px;
  position: fixed;
  top: 10px;
  left: 15px;
  cursor: pointer;
  display: none; }
  @media only screen and (max-width: 960px) {
    .mobile-nav-toggle {
      display: block; } }
  .mobile-nav-toggle .bar {
    transition: .2s all;
    display: block;
    background-color: #999999;
    width: 100%;
    height: 5px;
    margin: 7px 0; }
    .mobile-nav-toggle .bar.white {
      background-color: white; }

#mobile-nav .menu {
  list-style-type: none;
  color: white;
  padding: 0;
  margin: 0;
  text-align: center;
  padding: 40px 20px; }
  #mobile-nav .menu li {
    line-height: 20px;
    padding: 10px 0;
    font-size: 18px; }
    #mobile-nav .menu li.cat {
      text-transform: uppercase; }
    #mobile-nav .menu li.devider {
      border-top: 1px solid white;
      padding: 0;
      margin: 20px; }
    #mobile-nav .menu li span {
      cursor: pointer; }
  #mobile-nav .menu a {
    color: white; }
    #mobile-nav .menu a:active, #mobile-nav .menu a:hover, #mobile-nav .menu a:focus {
      text-decoration: none; }

nav {
  display: none;
  width: 100%;
  height: 70px;
  padding: 15px 0;
  background-color: white;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  z-index: 1; }
  @media only screen and (max-width: 960px) {
    nav {
      display: none !important; } }
  nav .logo {
    width: 40px;
    float: left; }
    nav .logo img {
      width: 100%; }
  nav .menu {
    list-style-type: none;
    margin-left: 80px;
    padding: 0;
    text-align: right; }
    nav .menu li {
      cursor: pointer;
      display: inline-block;
      height: 32px;
      line-height: 40px;
      font-size: 14px;
      text-transform: uppercase;
      margin-right: 68px;
      letter-spacing: 1px;
      transition: .2s all;
      border-bottom: none; }
      @media only screen and (max-width: 1280px) {
        nav .menu li {
          margin-right: 32px;
          font-size: 12px; } }
      nav .menu li:last-child {
        padding-right: 0;
        margin-right: 0; }
      nav .menu li:hover {
        color: #BEA731;
        border-bottom: 1px solid #BEA731; }
        nav .menu li:hover a {
          color: #BEA731; }
      nav .menu li a {
        color: black; }
        nav .menu li a:hover {
          color: #BEA731;
          text-decoration: none; }

.overlay {
  display: none;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2; }
  .overlay .popup-content {
    background-color: white;
    position: relative;
    top: calc(50% - 300px);
    margin: 0 auto;
    width: 550px;
    height: 600px;
    overflow: auto;
    padding: 50px 40px;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.3); }
    @media only screen and (max-width: 640px) {
      .overlay .popup-content {
        width: 90%;
        top: calc(50% - 220px);
        height: 430px;
        padding: 30px; } }
    .overlay .popup-content h5 {
      text-transform: uppercase;
      letter-spacing: 2px;
      font-size: 22px;
      margin-top: 50px; }
      .overlay .popup-content h5.no-top-margin {
        margin-top: 0; }
      @media only screen and (max-width: 640px) {
        .overlay .popup-content h5 {
          margin-top: 20px; } }
    .overlay .popup-content p {
      font-family: Georgia, serif;
      font-style: italic;
      color: #777777;
      line-height: 24px; }
      .overlay .popup-content p a {
        color: #BEA731; }
    .overlay .popup-content .logo {
      width: 120px; }
      @media only screen and (max-width: 640px) {
        .overlay .popup-content .logo {
          width: 80px; } }
    .overlay .popup-content .close-icon {
      color: #CCC;
      transition: 0.2s all;
      position: absolute;
      top: 0;
      right: 30px;
      font-size: 54px;
      line-height: 64px;
      cursor: pointer; }
      .overlay .popup-content .close-icon:hover {
        color: #777777; }
      @media only screen and (max-width: 640px) {
        .overlay .popup-content .close-icon {
          right: 20px;
          line-height: 60px; } }

/*# sourceMappingURL=app.css.map */